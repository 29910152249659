/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { minBp } from '../../lib/theme';
import { useShowHomes } from '../../context/ShowHomesContext';

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 8px 16px;
  width: 100%;
  font-weight: ${props =>
    props.type === 'distance' &&
    String(props.optionValue) === String(props.currentValue)
      ? '400'
      : '300'};

  &:hover {
    font-weight: 400;
  }

  &:focus-within {
    outline: 2px solid #082d60;
    outline-offset: -2px;
    background-color: #f5f5f5;
  }
`;

const HiddenCheckbox = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const CustomCheckboxSpan = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #082d60;
  position: relative;
  transition: all 0.2s ease-in-out;

  @media ${minBp('tabletLarge')} {
    display: ${props => (props.type === 'distance' ? 'none' : 'inline-block')};
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 50%;
    top: 42%;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 1px 1px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  ${HiddenCheckbox}:checked + & {
    background: #082d60;
    border-color: #082d60;

    &:after {
      opacity: 1;
    }
  }
`;

const DropdownComponent = styled.div`
  position: relative;
  font-family: 'Lato', serif;
  height: 48px;
  font-size: 16px;
  color: #082d60;
  font-weight: 300;
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-width: ${props => (props.type === 'distance' ? 'none' : '200px')};
  z-index: ${props => (props.isOpen ? 2 : 0)};
  border-radius: 4px;
  transition: backgroundColor 0.2s ease-in-out;
  animation: fadeIn 0.3s ease-in;

  @media ${minBp('tabletLarge')} {
    height: ${props => (props.type === 'distance' ? 'auto' : '48px')};
    border-radius: 0;
    filter: ${props =>
      props.type === 'distance'
        ? 'none'
        : 'drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.25))'};

    &:hover {
      background: ${props =>
        props.type === 'distance' ? '' : 'rgb(247, 247, 247)'};
    }
  }

  .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    z-index: ${props => (props.isOpen ? '2' : '1')};
    border-radius: 0 0 15px 15px;
    display: ${props => (props.isOpen ? 'block' : 'none')};
    padding: 15px 0 10px 0;

    @media ${minBp('tabletLarge')} {
      top: ${props => (props.type === 'distance' ? '150%' : '100%')};
      padding: ${props =>
        props.type === 'distance' ? '0 0 5px 0' : '15px 0 10px 0'};
      filter: ${props =>
        props.type === 'distance'
          ? 'drop-shadow(0px 4px 4px rgba(0,0,0,0.05))'
          : 'none'};
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 15px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      pointer-events: none;

      @media ${minBp('tabletLarge')} {
        display: ${props => (props.type === 'distance' ? 'none' : 'block')};
      }
    }
  }

  .dropdown-content-inner {
    max-height: 250px;
    overflow-y: auto;
    animation: fadeIn 0.3s ease-in;

    /* Custom scrollbar styling */
    &::-webkit-scrollbar {
      width: 5px;
      background: white;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
      background: white;
      border-radius: 20px;
      margin-block: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      background: #082d60;
      border-radius: 20px;
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: #082d60 #fff;
  }

  .filter-label {
    position: absolute;
    top: -22px;
    font-family: 'Lato', serif;
    font-size: 12px;
    font-weight: 400;
    color: #082d60;

    @media ${minBp('tabletLarge')} {
      display: none;
    }
  }

  &:focus {
    outline: 2px solid #082d60;
    outline-offset: 2px;
  }
`;

const Title = styled.div`
  padding: 12px 32px 12px 16px;
  color: #082d60;
  font-family: 'Lato', serif;
  font-size: 16px;
  font-weight: 400;
  position: relative;
  width: 100%;
  height: 100%;

  @media ${minBp('tabletLarge')} {
    padding: ${props =>
      props.type === 'distance' ? '0 24px' : '12px 32px 12px 16px'};
  }

  &:after {
    content: '';
    position: absolute;
    top: 40%;
    right: 16px;
    width: 8px;
    height: 8px;
    border-right: 2px solid #082d60;
    border-top: 2px solid #082d60;
    transform: rotate(135deg);

    @media ${minBp('tabletLarge')} {
      top: ${props => (props.type === 'distance' ? 20 : 40)}%;
      right: ${props => (props.type === 'distance' ? 0 : 16)}px;
    }
  }

  .distance-text {
    @media ${minBp('tabletLarge')} {
      &:before {
        content: 'within ';
      }
    }
  }
`;

const GroupHeader = styled.div`
  font-family: 'Lato', serif;
  font-weight: 700;
  color: #082d60;
  padding: 8px 16px;
  font-size: 14px;
`;

const Dropdown = ({
  label,
  type,
  options,
  selectedOptions,
  onSelectionChange,
  value,
  onChange,
  defaultValue
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [focusIndex, setFocusIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const optionsRef = useRef([]);
  const { state } = useShowHomes();
  const { labels } = state;

  const handleKeyDown = event => {
    switch (event.key) {
      case 'Enter':
      case ' ':
        event.preventDefault();
        setIsOpen(!isOpen);
        if (!isOpen) {
          setFocusIndex(0);
        }
        break;
      case 'Escape':
        setIsOpen(false);
        setFocusIndex(-1);
        if (dropdownRef.current) {
          dropdownRef.current.focus();
        }
        break;
      case 'ArrowDown':
        if (isOpen) {
          event.preventDefault();
          setFocusIndex(prev =>
            prev < optionsRef.current.length - 1 ? prev + 1 : prev
          );
        }
        break;
      case 'ArrowUp':
        if (isOpen) {
          event.preventDefault();
          setFocusIndex(prev => (prev > 0 ? prev - 1 : prev));
        }
        break;
    }
  };

  const handleBlur = event => {
    if (!dropdownRef.current?.contains(event.relatedTarget)) {
      setIsOpen(false);
      setFocusIndex(-1);
    }
  };

  useEffect(() => {
    const handleFocus = () => {
      if (isOpen && focusIndex >= 0 && focusIndex < optionsRef.current.length) {
        const element = optionsRef.current[focusIndex];
        if (element) {
          element.focus();
        }
      }
    };

    handleFocus();
  }, [focusIndex, isOpen]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const getDropdownTitle = () => {
    if (type === 'bedrooms') {
      if (!selectedOptions?.length) return labels?.bedroomsFilterUnset;
      const sortedValues = selectedOptions
        .map(value => parseInt(value))
        .sort((a, b) => a - b);
      return labels?.bedroomsFilter.replace('{0}', sortedValues.join(', '));
    }

    if (type === 'distance') {
      const selectedOption = options.find(opt => opt.value === value);
      return selectedOption ? (
        <span className="distance-text">{selectedOption.label}</span>
      ) : (
        <span className="distance-text">{defaultValue} miles</span>
      );
    }

    if (type === 'properties') {
      if (!selectedOptions?.length) return labels?.propertyNamesFilterUnset;
      return labels?.propertyNamesFilter.replace('{0}', selectedOptions.length);
    }

    if (!selectedOptions?.length) return 'Select...';
    return selectedOptions.join(', ');
  };

  const handleCheckboxChange = (value, event) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (type === 'distance') {
      onChange(value);
      setIsOpen(false);
      return;
    }

    if (selectedOptions) {
      const newSelection = selectedOptions.includes(value)
        ? selectedOptions.filter(item => item !== value)
        : [...selectedOptions, value];

      onSelectionChange(newSelection);
    }

    if (dropdownRef.current) {
      dropdownRef.current.focus();
    }
  };

  const handleOptionKeyDown = (e, value) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.stopPropagation();
      handleCheckboxChange(value, e);
    }
  };

  const renderOptions = () => {
    if (!options || !Array.isArray(options)) return null;

    // For property types with the new API structure
    if (type === 'properties') {
      return options.map((group, groupIndex) => {
        if (!group || !Array.isArray(group.Names)) return null;

        // Sort the Names array alphabetically
        const sortedNames = [...group.Names].sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLowerCase())
        );

        return (
          <div
            key={groupIndex}
            role="group"
            aria-label={`${group.Bedrooms} Bedrooms`}
          >
            <GroupHeader>{group.Bedrooms} Bedroom</GroupHeader>
            {sortedNames.map((name, index) => (
              <CheckboxLabel
                key={`${name}-${index}`}
                ref={element => {
                  optionsRef.current[index] = element;
                }}
                type={type}
                optionValue={name.toLowerCase()}
                role="option"
                aria-selected={selectedOptions?.includes(name.toLowerCase())}
                tabIndex={isOpen ? 0 : -1}
                onKeyDown={e => {
                  handleOptionKeyDown(e, name.toLowerCase());
                }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                <HiddenCheckbox
                  type="checkbox"
                  checked={selectedOptions?.includes(name.toLowerCase())}
                  onChange={() => {
                    handleCheckboxChange(name.toLowerCase());
                  }}
                  tabIndex={-1}
                  aria-hidden="true"
                />
                <CustomCheckboxSpan type={type} />
                {name}
              </CheckboxLabel>
            ))}
          </div>
        );
      });
    }

    // For regular options (bedrooms and distance)
    return options.map((option, index) => (
      <CheckboxLabel
        key={option.value}
        ref={element => {
          optionsRef.current[index] = element;
        }}
        type={type}
        optionValue={option.value}
        currentValue={value}
        role="option"
        aria-selected={
          type === 'distance'
            ? value === option.value
            : selectedOptions?.includes(option.value)
        }
        tabIndex={isOpen ? 0 : -1}
        onKeyDown={e => {
          handleOptionKeyDown(e, option.value);
        }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <HiddenCheckbox
          type={type === 'distance' ? 'radio' : 'checkbox'}
          checked={
            type === 'distance'
              ? value === option.value
              : selectedOptions?.includes(option.value)
          }
          onChange={() => {
            handleCheckboxChange(option.value);
          }}
          tabIndex={-1}
          aria-hidden="true"
        />
        <CustomCheckboxSpan type={type} />
        {option.label}
      </CheckboxLabel>
    ));
  };

  return (
    <DropdownComponent
      ref={dropdownRef}
      isOpen={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      type={type}
      role="combobox"
      aria-expanded={isOpen}
      aria-haspopup="listbox"
      aria-controls="dropdown-list"
      aria-label={label}
      tabIndex={0}
    >
      <div className="filter-label" id={`${type}-label`}>
        {label}
      </div>
      <Title type={type}>
        <span>{getDropdownTitle()}&nbsp;</span>
      </Title>

      <div
        className="dropdown-content"
        role="listbox"
        id="dropdown-list"
        aria-labelledby={`${type}-label`}
      >
        <div className="dropdown-content-inner">{renderOptions()}</div>
      </div>
    </DropdownComponent>
  );
};

export default Dropdown;
