import styled, { keyframes } from 'styled-components';
import { minBp } from '../../lib/theme';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  text-decoration: none;
  background-color: #f0f0f0;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-out forwards;
  animation-delay: ${({ index }) => `${index * 0.1}s`};

  @media ${minBp('tablet')} {
    flex-direction: row;
    height: 300px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Content = styled.div`
  width: 100%;
  padding: 25px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${minBp('tablet')} {
    padding: 40px;
    align-items: flex-start;
  }
`;

export const Title = styled.h3`
  color: #153372;
  font-size: 1rem;
  margin: 0 0 10px;
  font-family: 'Lato', serif;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
  max-height: 2.6em;
  text-wrap: balance;
  text-align: center;

  @media ${minBp('tablet')} {
    font-size: 1.625rem;
    text-align: left;
  }
`;

export const Summary = styled.p`
  font-size: 0.875rem;
  line-height: 24px;
  margin: 0 0 15px;
  font-family: 'Lato', serif;
  color: #082d60;
  flex-grow: 1;
  text-wrap: balance;
  text-align: center;

  @media ${minBp('tablet')} {
    line-height: 1.6;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    text-align: left;
  }
`;

export const Cta = styled.a`
  color: #ffffff;
  font-size: 1rem;
  font-family: 'Lato', serif;
  text-decoration: none;
  background-color: #0c2c64;
  padding: 12px 60px;
  display: inline-block;
  margin-top: auto;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: #0a2450;
  }

  @media ${minBp('tablet')} {
    padding: 12px 40px;
    font-size: 1.125rem;
  }
`;
