import React from 'react';
import styled from 'styled-components';
import { useShowHomes } from '../../context/ShowHomesContext';

const LoadMoreButton = styled.button`
  width: auto;
  padding: 16px 40px;
  background: #fff;
  border: 1px solid #082d60;
  color: white;
  background: #082d60;
  font-family: 'Lato', serif;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 24px;
  margin: 24px auto 0;

  &:hover {
    background: white;
    color: #082d60;
  }
`;

const ShowHomesLoadMore = () => {
  const { state, dispatch } = useShowHomes();

  const handleLoadMore = () => {
    dispatch({
      type: 'SET_DISPLAYED_COUNT',
      payload: state.displayedCount + 20
    });
  };

  const remainingResults = state.totalResults - state.displayedCount;

  if (remainingResults <= 0) return null;

  return (
    <LoadMoreButton onClick={handleLoadMore}>
      {state.labels?.showMoreCta || 'Show More'}
    </LoadMoreButton>
  );
};

export default ShowHomesLoadMore;
