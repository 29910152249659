/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import ShowHomeCard from '../ShowHomeCard/ShowHomeCard';
import Dropdown from '../ShowHomesFilters/Dropdown';
import { useShowHomes } from '../../context/ShowHomesContext';
import { minBp } from '../../lib/theme';
import HomeLogo from '../../assets/icons/HomeLogo.svg';
import ShowHomesLoadMore from './ShowHomesLoadMore';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const ShowHomesListComponent = styled.div`
  width: 100%;
  height: 100%;
  font-family: 'Lato', serif;
  padding: 24px 16px;
  animation: fadeIn 0.3s ease-in;

  @media ${minBp('tabletLarge')} {
    padding: 24px;
  }

  .instruction-text {
    font-family: 'Lato', serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    color: #082d60;
    text-align: center;
    padding-top: 16px;
  }
`;

const ResultCount = styled.p`
  font-family: 'Lato', serif;
  font-size: 16px;
  color: #082d60;
  margin-bottom: 24px;
  font-weight: 300;
  margin: 0;

  @media ${minBp('tabletLarge')} {
    font-size: 18px;
    font-weight: 400;
  }
`;

const ShowHomesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media ${minBp('tabletLarge')} {
    gap: 16px;
    overflow-y: auto;
    max-height: 704px;
    padding-right: 16px;
    padding-bottom: 4px;
  }

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 10px;
    background: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f2f2;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #082d60;
    border-radius: 20px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #082d60 #f1f2f2;
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const DesktopDistanceWrapper = styled.div`
  display: none;

  @media ${minBp('tabletLarge')} {
    display: block;
  }
`;

const distanceOptions = [
  { value: '20', label: '20 miles' },
  { value: '40', label: '40 miles' },
  { value: '60', label: '60 miles' },
  { value: '80', label: '80 miles' },
  { value: '100', label: '100 miles' }
];

const NoResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 48px;

  img {
    width: 120px;
    height: 120px;
    margin-bottom: 16px;
  }

  h2 {
    font-family: 'Lato', serif;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  p {
    font-family: 'Lato', serif;
    font-size: 16px;
    font-weight: 300;
  }
`;

const ShowHomesList = ({ results, isLoading, error, hasSearched }) => {
  const { state, dispatch } = useShowHomes();
  const { labels } = state;

  useEffect(() => {
    if (results?.Developments) {
      const totalHomes = results.Developments.reduce((acc, development) => {
        return acc + (development.ShowHomes?.length || 0);
      }, 0);
      dispatch({ type: 'SET_TOTAL_RESULTS', payload: totalHomes });
    }
  }, [results, dispatch]);

  const handleRadiusChange = value => {
    dispatch({ type: 'SET_RADIUS', payload: value });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error || state.error) {
    return (
      <ShowHomesListComponent>
        <ErrorMessage />
      </ShowHomesListComponent>
    );
  }

  const allShowHomes =
    results?.Developments?.reduce((acc, development) => {
      if (development.ShowHomes && development.ShowHomes.length > 0) {
        const showHomesWithDevelopment = development.ShowHomes.map(
          showHome => ({
            showHome,
            development
          })
        );
        return [...acc, ...showHomesWithDevelopment];
      }
      return acc;
    }, []) || [];

  const paginatedShowHomes = allShowHomes.slice(0, state.displayedCount);

  return (
    <ShowHomesListComponent>
      <ListHeader>
        <ResultCount>
          {allShowHomes.length === 1
            ? labels?.numberOfShowHomes
                .replace('{0}', allShowHomes.length)
                .slice(0, -1)
            : labels?.numberOfShowHomes.replace('{0}', allShowHomes.length)}
        </ResultCount>
        <DesktopDistanceWrapper>
          <Dropdown
            label={labels?.withinMiles.replace('{0}', '')}
            type="distance"
            options={distanceOptions}
            value={state.radius}
            onChange={handleRadiusChange}
            defaultValue="40"
          />
        </DesktopDistanceWrapper>
      </ListHeader>

      {state.searchTerm &&
      (!results?.Developments || results.Developments.length === 0) ? (
        <NoResultsWrapper>
          <img src={HomeLogo} alt="" aria-hidden="true" />
          <h2>{labels?.noResultsHeading}</h2>
          <p>{labels?.noResultsDescription}</p>
        </NoResultsWrapper>
      ) : (
        <>
          <ShowHomesListContainer>
            {paginatedShowHomes.map((item, index) => (
              <ShowHomeCard
                key={`${item.development.ID.Guid}-${index}`}
                showHome={item.showHome}
                development={item.development}
                index={index}
              />
            ))}
            <ShowHomesLoadMore />
          </ShowHomesListContainer>
        </>
      )}
    </ShowHomesListComponent>
  );
};

export default ShowHomesList;
