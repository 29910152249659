/* eslint-disable react/prop-types */
import React, { useRef, useEffect, Fragment, useContext } from 'react';
import {
  DesktopFilterComponent,
  Wrap,
  Inner,
  InputWrap,
  InputIcon,
  InputButton,
  Input,
  FilterWrap,
  ResultsWrap,
  Result,
  ResultsInner,
  ResultIcon
} from './DesktopFilters.styles';

import { Context } from '../../../pages/search-and-results/search-and-results';

import FilterGroup from '../../filter-group/FilterGroup';

import SearchIcon from '../../../assets/icons/Search.svg';
import SelectedMarker from '../../../assets/icons/SelectedMarker.svg';

const DesktopFilters = ({
  filters,
  handleSaveFilter,
  isFilterSticky,
  setIsFilterSticky,
  selectedRadiusValue,
  onQueryChange,
  onSearchTrigger,
  handleKeyPressed,
  results,
  onFetchProperties
}) => {
  const filterRef = useRef(null);
  const resultsWrapRef = useRef(null);
  const filtersContext = useContext(Context);

  const {
    location,
    query,
    radius,
    toggleSearchInput,
    fallbackRadius
  } = filtersContext.state;
  const { searchLocationLabel } = filtersContext.state.contentData.labels;

  const scrollCallBack = () => {
    if (!filterRef.current) {
      return;
    }
    if (window.pageYOffset > filterRef.current.offsetTop) {
      setIsFilterSticky(true);
    } else {
      setIsFilterSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollCallBack);
    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        resultsWrapRef.current &&
        !resultsWrapRef.current.contains(event.target)
      ) {
        filtersContext.dispatch({
          type: 'TOGGLE_SEARCH_INPUT',
          payload: false
        });
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  function generateFilterTitle(title) {
    if (!title) {
      return;
    }

    if (location && location.radius) {
      return title.replace('{0}', radius || location.radius);
    }

    if (radius) {
      return title.replace('{0}', radius);
    }

    return title.replace('{0}', fallbackRadius == null ? ' ' : fallbackRadius);
  }

  return (
    <DesktopFilterComponent ref={filterRef} isSticky={isFilterSticky}>
      <Wrap>
        <Inner>
          <InputWrap>
            <InputButton onClick={() => onSearchTrigger()}>
              <InputIcon src={SearchIcon} />
            </InputButton>
            <Input
              value={query}
              onChange={e => onQueryChange(e.target.value)}
              onKeyPress={handleKeyPressed}
              placeholder={searchLocationLabel || ''}
              onFocus={e => (e.target.placeholder = '')}
            />
            {results && results.length && toggleSearchInput ? (
              <ResultsWrap ref={resultsWrapRef}>
                {results.map((result, key) => (
                  <Fragment key={key}>
                    {result.Name && result.County ? (
                      <ResultsInner>
                        <ResultIcon src={SelectedMarker} />
                        <Result
                          onClick={() => {
                            const resultString = `${result.Name}, ${result.County}`;

                            onQueryChange(resultString);
                            onSearchTrigger(resultString);
                            onFetchProperties(resultString);
                          }}
                        >
                          {result.Name}, {result.County}
                        </Result>
                      </ResultsInner>
                    ) : null}
                  </Fragment>
                ))}
              </ResultsWrap>
            ) : null}
          </InputWrap>
          <FilterWrap>
            {filters
              ? filters.map((filter, i) => (
                  <>
                    {/* //! REMOVE THE BELOW CONDITIONAL ONCE THE CLIENT HAS REQUESTED PROPERTY TYPES
                     //! BACK AFTER RELEASE! THIS IS A TEMPORARY SOLUTION TO REMOVE FILTER PROPERTY TYPES */}
                    {filter.id !== 'b119da3d-3576-4829-9fff-67adf0ba12fb' ? (
                      <FilterGroup
                        filter={filter}
                        filterTitle={generateFilterTitle(filter.title)}
                        index={i}
                        key={i}
                        handleSaveFilter={handleSaveFilter}
                        selectedRadiusValue={selectedRadiusValue}
                        fetchProperties={onFetchProperties}
                        desktopFilters
                      />
                    ) : null}
                  </>
                ))
              : null}
          </FilterWrap>
        </Inner>
      </Wrap>
    </DesktopFilterComponent>
  );
};

export default DesktopFilters;
