import React from 'react';
import styled from 'styled-components';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 48px;
  border-radius: 4px;
  margin: 24px 0;
  position: absolute;
  inset: 100px 0 0 0;
  animation: fadeIn 0.3s ease-in;

  h2 {
    font-family: 'Lato', serif;
    font-size: 24px;
    font-weight: 400;
    color: #082d60;
    margin-bottom: 8px;
  }

  p {
    font-family: 'Lato', serif;
    font-size: 16px;
    font-weight: 300;
    color: #666;
  }
`;

const ErrorMessage = () => (
  <ErrorWrapper>
    <h2>Something went wrong</h2>
    <p>There was a problem fetching the show homes. Please try again later.</p>
  </ErrorWrapper>
);

export default ErrorMessage;
