/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { minBp } from '../../lib/theme';
import LocationIcon from '../../assets/icons/Location.svg';
import BedIcon from '../../assets/icons/KingBed.svg';

const DrawerContainer = styled.div`
  position: absolute;
  background: white;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2));
  transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
  z-index: 1;
  border-radius: 30px 30px 0 0;
  pointer-events: ${props => (props.visible ? 'all' : 'none')};
  padding: 20px;
  top: 30%;
  width: 100%;
  max-height: 70%;
  transform: translateY(${props => (props.isOpen ? '0' : 'calc(100% + 20px)')});
  height: 100%;
  opacity: ${props => (props.visible ? '1' : '0')};

  @media ${minBp('tabletLarge')} {
    min-width: 260px;
    max-width: 400px;
    max-height: none;
    top: 10%;
    right: 0;
    border-radius: 8px;
    transform: translateX(${props => (props.isOpen ? '0' : '100%')});
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DrawerGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Distance = styled.div`
  font-family: 'Lato', serif;
  font-size: 12px;
  font-weight: 400;
  color: #082d60;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LocationIconSvg = styled.img`
  width: 16px;
  height: 16px;
`;

const BedIconSvg = styled.img`
  width: 16px;
  height: 16px;
`;

const CloseButton = styled.button`
  cursor: pointer;
  padding: 8px;
  background: none;
  border: none;
  position: relative;
  transition: transform 0.3s;
  will-change: transform;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 2px;
    background: #082d60;
  }
  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }
`;

const DevelopmentAddress = styled.h3`
  font-family: 'Lato', serif;
  font-size: 12px;
  font-weight: 400;
  color: #082d60;
  margin: 0;
`;

const DevelopmentName = styled.h2`
  font-family: 'Lato', serif;
  font-size: 16px;
  font-weight: 400;
  color: #082d60;
  margin: 0;
`;

const ShowHomesList = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 10px;
    background: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f2f2;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #082d60;
    border-radius: 20px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #082d60 #f1f2f2;

  @media ${minBp('tabletLarge')} {
    max-height: 650px;
  }
`;

const ShowHomeCard = styled.a`
  position: relative;
  display: flex;
  gap: 16px;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px;
    height: 10px;
    border-top: 2px solid #082d60;
    border-right: 2px solid #082d60;
    transition: transform 0.2s ease;
    transform: translateY(-50%) rotate(45deg);
  }

  &:hover {
    &:after {
      transform: translateY(-50%) rotate(45deg) scale(1.2);
      right: 15px;
    }
  }
  &:active {
    &:after {
      transform: translateY(-50%) rotate(45deg) scale(0.9);
    }
  }
`;

const ShowHomeImage = styled.img`
  width: 68px;
  height: 68px;
  object-fit: cover;
`;

const ShowHomeDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const PropertyType = styled.h3`
  font-family: 'Lato', serif;
  font-size: 16px;
  color: #082d60;
  margin: 0;
`;

const Bedrooms = styled.p`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Lato', serif;
  font-size: 14px;
  color: #082d60;
  margin: 0;
`;

const SideDrawer = ({ isOpen, onClose, development, searchTerm }) => {
  return (
    <DrawerContainer isOpen={isOpen} visible={!!development}>
      {development && (
        <>
          <DrawerHeader>
            <DrawerGroup>
              <Distance>
                {searchTerm && (
                  <>
                    <LocationIconSvg src={LocationIcon} alt="location" />
                    {Math.round(development.DistanceInMiles)} miles
                  </>
                )}
              </Distance>
              <CloseButton onClick={onClose}></CloseButton>
            </DrawerGroup>
            <DevelopmentName>{development.Name}</DevelopmentName>
            <DevelopmentAddress>
              {development.Town}, {development.PostCode}
            </DevelopmentAddress>
          </DrawerHeader>
          <ShowHomesList>
            {development.ShowHomes.map((showHome, index) => (
              <ShowHomeCard key={index} href={development.PageUrl}>
                <ShowHomeImage
                  src={showHome.ImageUrl}
                  alt={showHome.PropertyType}
                />
                <ShowHomeDetails>
                  <PropertyType>{showHome.PropertyType}</PropertyType>
                  <Bedrooms>
                    <BedIconSvg src={BedIcon} alt="bed" />
                    {showHome.Bedrooms} bedrooms
                  </Bedrooms>
                </ShowHomeDetails>
              </ShowHomeCard>
            ))}
          </ShowHomesList>
        </>
      )}
    </DrawerContainer>
  );
};

export default SideDrawer;
