import styled, { keyframes } from 'styled-components';
import { minBp } from '../../lib/theme';

export const NewsListComponent = styled.section`
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 40px 20px 60px;

  @media ${minBp('desktop')} {
    padding: 40px 0 80px;
  }
`;

export const NewsGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 40px;
`;

export const LoadMoreButton = styled.button`
  background-color: #0c2c64;
  height: 48px;
  padding: 10px 20px;
  cursor: pointer;
  width: calc(100% - 40px);
  margin: 0 auto;
  color: #fff;
  border: none;
  align-self: center;
  font-size: 18px;
  font-family: 'Lato', serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  @media (min-width: 768px) {
    width: auto;
    min-width: 300px;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoadingSpinner = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid #ffffff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export const InitialLoadingContainer = styled.div`
  width: 100%;
  height: 400px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
