import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { getHostname } from '../utility/helpers';

const ShowHomesContext = createContext();

const initialState = {
  searchTerm: '',
  radius: '40',
  center: null,
  results: null,
  rawResults: null,
  labels: null,
  displayedCount: 20,
  totalResults: 0,
  error: null
};

function showHomesReducer(state, action) {
  switch (action.type) {
    case 'SET_SEARCH_RESULTS':
      return {
        ...state,
        results: action.payload.filteredResults,
        rawResults: action.payload.rawResults,
        searchTerm: action.payload.searchTerm,
        filters: action.payload.searchParams
      };

    case 'SET_LABELS':
      return {
        ...state,
        labels: action.payload
      };

    case 'SET_RADIUS':
      return {
        ...state,
        radius: action.payload
      };

    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
        filters: {
          ...state.filters,
          term: action.payload,
          bedrooms: action.bedrooms,
          propertytypes: action.propertytypes,
          radius: action.radius
        }
      };

    case 'SET_DISPLAYED_COUNT':
      return {
        ...state,
        displayedCount: action.payload
      };

    case 'SET_TOTAL_RESULTS':
      return {
        ...state,
        totalResults: action.payload
      };

    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload
      };

    case 'UPDATE_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          bedrooms: action.payload.bedrooms,
          propertytypes: action.payload.propertytypes,
          radius: action.payload.radius
        }
      };

    default:
      return state;
  }
}

export function ShowHomesProvider({ children }) {
  const [state, dispatch] = useReducer(showHomesReducer, initialState);

  useEffect(() => {
    const fetchLabels = async () => {
      try {
        const response = await fetch(
          `${getHostname()}/bloorapi/showhomelabels`
        );
        if (!response.ok) throw new Error('Failed to fetch labels');
        const labels = await response.json();
        dispatch({ type: 'SET_LABELS', payload: labels });
        dispatch({ type: 'SET_ERROR', payload: null });
      } catch (error) {
        console.error('Error fetching labels:', error);
        dispatch({ type: 'SET_ERROR', payload: 'Failed to load page content' });
      }
    };

    fetchLabels();
  }, []);

  return (
    <ShowHomesContext.Provider value={{ state, dispatch }}>
      {children}
    </ShowHomesContext.Provider>
  );
}

export function useShowHomes() {
  const context = useContext(ShowHomesContext);
  if (!context) {
    throw new Error('useShowHomes must be used within a ShowHomesProvider');
  }
  return context;
}
