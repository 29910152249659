/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import { LoadScript } from '@react-google-maps/api';
import { ShowHomesWrappedMap } from './ShowHomesWrappedMap';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import { minBp } from '../../lib/theme';
import { useShowHomes } from '../../context/ShowHomesContext';

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  animation: fadeIn 0.3s ease-in;

  @media ${minBp('tabletLarge')} {
    gap: 0;
  }
`;

const MapHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 0 16px;

  @media ${minBp('tabletLarge')} {
    display: none;
  }
`;

const ResultCount = styled.p`
  font-family: 'Lato', serif;
  font-size: 16px;
  color: #082d60;
  margin: 0;
  font-weight: 300;
`;

const MapComponent = styled.div`
  width: 100%;
  height: 600px;
  border-radius: 4px;
  overflow: hidden;

  @media ${minBp('tabletLarge')} {
    height: 800px;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  border-radius: 4px;

  @media ${minBp('tabletLarge')} {
    height: 800px;
  }
`;

const libraries = ['places', 'geometry'];

const ShowHomesMap = ({ selectedDistance, isLoading }) => {
  const { state } = useShowHomes();
  const { labels } = state;

  if (isLoading) return <LoadingSpinner />;

  const allShowHomes =
    state.results?.Developments?.reduce((acc, development) => {
      if (development.ShowHomes && development.ShowHomes.length > 0) {
        const showHomesWithDevelopment = development.ShowHomes.map(
          showHome => ({
            showHome,
            development
          })
        );
        return [...acc, ...showHomesWithDevelopment];
      }
      return acc;
    }, []) || [];

  return (
    <MapWrapper>
      <MapHeader>
        <ResultCount>
          {allShowHomes.length === 1
            ? labels?.numberOfShowHomes
                .replace('{0}', allShowHomes.length)
                .slice(0, -1)
            : labels?.numberOfShowHomes.replace('{0}', allShowHomes.length)}
        </ResultCount>
      </MapHeader>
      <LoadScript
        googleMapsApiKey="AIzaSyDBRj0yyBtQ50o8geSjePHVbKr_7zXsrrk"
        libraries={libraries}
        loadingElement={
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        }
      >
        <MapComponent>
          <ShowHomesWrappedMap
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            radius={selectedDistance}
          />
        </MapComponent>
      </LoadScript>
    </MapWrapper>
  );
};

export default ShowHomesMap;
