/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { getHostname } from '../../utility/helpers';
import NewsCard from './NewsCard';
import LoadingSpinner from '../loading-spinner/LoadingSpinner';
import {
  NewsListComponent,
  NewsGrid,
  LoadMoreButton,
  LoadingSpinner as LoadingSpinnerStyled,
  InitialLoadingContainer
} from './NewsList.styles';

const NewsList = ({ showMoreLabel }) => {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const fetchArticles = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${getHostname()}/bloorapi/newslist?page=${page}&pageSize=10`
      );
      const data = await response.json();

      if (page === 1) {
        setArticles(data.Items);
      } else {
        setArticles(prev => [...prev, ...data.Items]);
      }

      setMaxPages(data.MaxPages);
      setCurrentPage(data.Page);
    } catch (error) {
      console.error('Error fetching news articles:', error);
    }
    setIsLoading(false);
    setIsInitialLoad(false);
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  const handleLoadMore = () => {
    if (currentPage < maxPages) {
      fetchArticles(currentPage + 1);
    }
  };

  const getAnimationIndex = index => {
    return index % 10;
  };

  if (isInitialLoad) {
    return (
      <InitialLoadingContainer>
        <LoadingSpinner />
      </InitialLoadingContainer>
    );
  }

  return (
    <NewsListComponent>
      <NewsGrid>
        {articles.map((article, index) => (
          <NewsCard
            key={article.Id.Guid}
            title={article.Title}
            summary={article.Summary}
            image={article.Image}
            pageUrl={article.PageUrl}
            createdAt={article.CreatedAt}
            index={getAnimationIndex(index)}
          />
        ))}
      </NewsGrid>

      {currentPage < maxPages && (
        <LoadMoreButton onClick={handleLoadMore} disabled={isLoading}>
          {isLoading ? <LoadingSpinnerStyled /> : showMoreLabel}
        </LoadMoreButton>
      )}
    </NewsListComponent>
  );
};

export default NewsList;
