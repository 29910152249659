/* eslint-disable react/prop-types */
import React, { useState, useReducer, useEffect, useContext } from 'react';
import qs from 'qs';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import Filters from '../../components/filters/Filters';
import { reducer, initialState } from '../../store/reducer';
import Results from '../../components/results/Results';
import {
  getHostname,
  getSearchRadius,
} from '../../utility/helpers';

export const Context = React.createContext();

const SearchAndResults = ({ sourceId, searchLocation, goalEndpoint }) => {
  const SearchAndResultsContext = useContext(Context);

  const {
    radius,
    bedrooms,
    priceRange,
    selectedPropertyTypes,
    isLoading,
    initialRadius,
  } = SearchAndResultsContext.state;

  const [isFilterSticky, setIsFilterSticky] = useState(false); // Stick header
  const [error, setError] = useState(false);
  const [contentFetchComplete, setContentFetchComplete] = useState(false);
  const [initialSearch, setInitialSearch] = useState(true);

  const getLocation = async () => {
    let { location } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    location = location || searchLocation;

    if (location === 'user-location' && navigator.geolocation) {
      let latitude;
      let longitude;

      try {
        ({ latitude, longitude } = await new window.Promise(
          (resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
              (position) =>
                resolve({
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                }),
              () =>
                reject({ ok: false, statusText: 'Unable to retrieve location' })
            );
          }
        ));
      } catch (ex) {
        console.error(ex);
        setError(true);
        return '';
      }

      const res = await fetch(
        `${getHostname()}/bloorapi/locationaddress?latitude=${latitude}&longitude=${longitude}`
      );
      handleErrors(res);
      const resJson = await res.json();

      if (!resJson.Success) {
        setError(true);
        return '';
      }

      location = resJson.Address;
    }

    SearchAndResultsContext.dispatch({
      type: 'SET_SEARCHED_LOCATION',
      payload: location,
    });

    return location;
  };

  useEffect(() => {
    const setInitialLocation = async () => {
      SearchAndResultsContext.dispatch({
        type: 'INITIAL_LOCATION',
        payload: await getLocation(),
      });
    };

    setInitialLocation();
  }, []);

  function handleErrors(response) {
    if (!response.ok) {
      setError(true);
      throw Error(response.statusText);
    }
    setError(false);

    return response;
  }

  let selectedPropertyTypeValues = [];

  selectedPropertyTypes.map((property) => {
    return selectedPropertyTypeValues.push(Object.values(property).toString());
  });

  function setInitialRadius(location) {
    if (!location) {
      return;
    }

    SearchAndResultsContext.dispatch({
      type: 'INITIAL_RADIUS',
      payload: location.radius,
    });
  }
  function setDevelopmentAndPlotValues(developments) {
    if (developments && !developments.length) {
      SearchAndResultsContext.dispatch({
        type: 'DEVELOPMENT_COUNT',
        payload: 0,
      });
      SearchAndResultsContext.dispatch({
        type: 'PLOT_COUNT',
        payload: 0,
      });
    } else {
      const filterDevelopments = developments.filter(
        (development) => (development.homes && development.homes.length > 0) || development.status == "Coming Soon"
      );

      const calculatePlots = filterDevelopments
        ? filterDevelopments.reduce((a, b) => {
            return a + ((b.status == "Open" && b.homes && b.homes.length) || 0);
          }, 0)
        : 0;

      SearchAndResultsContext.dispatch({
        type: 'DEVELOPMENT_COUNT',
        payload: filterDevelopments.length,
      });
      SearchAndResultsContext.dispatch({
        type: 'PLOT_COUNT',
        payload: calculatePlots,
      });
    }
  }

  async function handleFetchProperties() {
    SearchAndResultsContext.dispatch({ type: 'SET_IS_LOADING', payload: true });

    //Get query parameters
    const params = new URLSearchParams(window.location.search);
    const urlRadius = params.get('radius');
    const location = params.get('location');
    const bedroomsParam = params.get('bedrooms');
    const bedroomsArray = bedroomsParam ? bedroomsParam.split(',').map(Number) : [];
    const minBedrooms = bedroomsArray.length > 0 ? Math.min(...bedroomsArray) : 0;
    const maxBedrooms = bedroomsArray.length > 0 ? Math.max(...bedroomsArray) : 0;

    // Set states
    if(urlRadius){ SearchAndResultsContext.dispatch({ type: 'SET_RADIUS', payload: urlRadius})};
    if(minBedrooms){ SearchAndResultsContext.dispatch({ type: 'MIN_BEDROOMS', payload: minBedrooms });}
    if(maxBedrooms){ SearchAndResultsContext.dispatch({ type: 'MAX_BEDROOMS', payload: maxBedrooms });}
    if(location){ SearchAndResultsContext.dispatch({ type: 'SET_SEARCHED_LOCATION', payload: location});}
    
    if((location && urlRadius) || (location && bedroomsParam)){
      const capitalizedLocationString = location.replace(/\b\w/g, c => c.toUpperCase());
      SearchAndResultsContext.dispatch({ type: 'SET_QUERY', payload: capitalizedLocationString });
      SearchAndResultsContext.dispatch({ type: 'TOGGLE_SEARCH_INPUT', payload: true });
      window.history.pushState({}, "", window.location.pathname); 
    }

    const term = location || await getLocation();

    const searchData = {
      term: term,
      minBedrooms: minBedrooms || bedrooms.min,
      maxBedrooms: maxBedrooms || bedrooms.max,
      minPrice: priceRange.min,
      maxPrice: priceRange.max,
      propertyTypes: selectedPropertyTypeValues,
      initialSearch: initialSearch,
      dataSourceId: sourceId,
      radius: getSearchRadius(radius, initialRadius),
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchData),
    };

    fetch(`${getHostname()}/api/v1/locations/search`, requestOptions)
      .then(handleErrors)
      .then((response) => response.json())
      .then((data) => {
        setInitialSearch(false);
        setInitialRadius(data.location);
        setDevelopmentAndPlotValues(data.developments);

        SearchAndResultsContext.dispatch({
          type: 'SET_PROPERTIES',
          payload: data.developments,
        });
        SearchAndResultsContext.dispatch({
          type: 'SET_COORDINATES',
          payload: data.location,
        });

        if (data.location && data.location.radius) {
          SearchAndResultsContext.dispatch({
            type: 'SET_FALLBACK_RADIUS',
            payload: data.location.radius,
          });
        }

        SearchAndResultsContext.dispatch({
          type: 'SET_IS_LOADING',
          payload: false,
        });
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    fetch(
      `${getHostname()}/api/v1/locations/search/content/44F8B44C-3D42-4205-9E6A-6757E936BA41`
    )
      .then((response) => response.json())
      .then((data) => {
        SearchAndResultsContext.dispatch({
          type: 'SET_CONTENT_DATA',
          payload: data,
        });
        setContentFetchComplete(true);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    handleFetchProperties();
  }, [radius]);

  return (
    <>
      {isLoading || !contentFetchComplete ? (
        <LoadingSpinner />
      ) : (
        <>
          <Filters
            isFilterSticky={isFilterSticky}
            setIsFilterSticky={setIsFilterSticky}
            onFetchProperties={handleFetchProperties}
          />
          <Results
            goalEndpoint={goalEndpoint}
            isFilterSticky={isFilterSticky}
            error={error}
            contentFetchComplete={contentFetchComplete}
          />
        </>
      )}
    </>
  );
};

const ContextProvider = ({ sourceId, searchLocation, goalEndpoint }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={{ state: state, dispatch: dispatch }}>
      <SearchAndResults
        sourceId={sourceId}
        searchLocation={searchLocation}
        goalEndpoint={goalEndpoint}
      />
    </Context.Provider>
  );
};

export default ContextProvider;
