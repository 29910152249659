import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import LocationSearch from '../src/pages/search-and-results/search-and-results';
import StandaloneMap from './components/standalone-map/StandaloneMap';
import NewsList from './components/news-list/NewsList';
import ShowHomeLocator from './components/ShowHomeLocator/ShowHomeLocator';

const pageElements = [
  {
    id: 'feature-locations-map',
    component: StandaloneMap
  },
  {
    id: 'feature-locations-app',
    component: LocationSearch
    },
    {
        id: 'feature-newslist-app',
        component: NewsList
    },
    {
        id: 'feature-showhomes-app',
        component: ShowHomeLocator
    }
];

const renderReactComponents = elements =>
  elements.forEach(({ id, component }) => {
    const domElement = document.getElementById(id);
    if (!domElement) return;
    const { dataset } = domElement || { dataset: {} };
    ReactDOM.render(
      React.createElement(component, dataset),
      document.getElementById(id)
    );
  });

renderReactComponents(pageElements);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
