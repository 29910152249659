export const filterShowHomes = (developments, filters) => {
  if (!developments) return [];

  // Ensure we have arrays for filters, even if empty
  const propertyTypes = (
    filters.propertytypes ||
    filters.properties ||
    []
  ).map(type => type.toLowerCase().trim());
  const bedrooms = (filters.bedrooms || []).map(Number);

  return developments.reduce((filteredDevelopments, development) => {
    // First check if development is within radius
    if (
      filters.radius &&
      development.DistanceInMiles > parseFloat(filters.radius)
    ) {
      return filteredDevelopments;
    }

    // Filter show homes based on all criteria
    const filteredShowHomes = development.ShowHomes.filter(showHome => {
      // Filter by bedrooms
      if (bedrooms.length > 0) {
        const showHomeBedrooms = parseInt(showHome.Bedrooms);
        if (!bedrooms.includes(showHomeBedrooms)) {
          return false;
        }
      }

      // Filter by property types
      if (propertyTypes.length > 0) {
        const showHomeType = showHome.PropertyType.toLowerCase().trim();
        if (!propertyTypes.includes(showHomeType)) {
          return false;
        }
      }

      return true;
    });

    // Only include developments that have matching show homes
    if (filteredShowHomes.length > 0) {
      filteredDevelopments.push({
        ...development,
        ShowHomes: filteredShowHomes
      });
    }

    return filteredDevelopments;
  }, []);
};
