import styled from 'styled-components';
import { maxBp, minBp } from '../../lib/theme';

export const ShowHomesFiltersComponent = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  gap: 16px;
  
  @media ${minBp('tabletLarge')} {
    padding: 30px 0 30px 40px;
    display: inline-flex;
    width: auto;
    flex-direction: row;
  }
}

  * {
    box-sizing: border-box;
  }
`;

export const MobileFilterButton = styled.button`
  color: #082d60;
  padding: 12px;
  border: none;
  font-family: 'Lato', serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;

  @media ${minBp('tabletLarge')} {
    display: none;
  }
`;

export const MobileFilterMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px;
  box-sizing: border-box;
  background: #fff;
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease;

  @media ${minBp('tabletLarge')} {
    z-index: 1;
    background: none;
    width: calc(100% - 340px);
    display: inline-flex;
    flex-direction: row;
    gap: 16px;
    position: static;
    padding: 30px 16px;
    opacity: 1;
    pointer-events: auto;
  }

  .filter-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    @media ${minBp('tabletLarge')} {
      display: none;
    }

    h2 {
      font-family: 'Lato', serif;
      color: #082d60;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
    }
  }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  font-size: 24px;
  color: #0c2c64;
  padding: 5px 10px;

  @media ${minBp('tabletLarge')} {
    display: none;
  }
`;

export const ViewToggle = styled.button`
  font-family: 'Lato', serif;
  font-size: 14px;
  text-decoration: underline;
  border: none;
  background: none;
  color: #082d60;
  padding: 6px;

  @media ${minBp('tabletLarge')} {
    display: none;
  }
`;

export const SearchButton = styled.button`
  background: #0c2c64;
  color: white;
  padding: 12px 50px;
  border: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  font-family: 'Lato', serif;
  font-size: 16px;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.09);
  transition: all 0.2s ease;
  margin-left: auto;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  @media ${maxBp('tabletLarge')} {
    display: none;
  }

  &:hover {
    background: ${props => (props.disabled ? '#0c2c64' : '#1a3a72')};
  }

  &:active {
    background: ${props => (props.disabled ? '#0c2c64' : '#0a2456')};
    transform: ${props => (props.disabled ? 'none' : 'translateY(1px)')};
  }

  &:focus {
    outline: 2px solid #082d60;
    outline-offset: 2px;
  }
`;

export const ApplyButton = styled.button`
  background: #0c2c64;
  color: white;
  padding: 14px 32px;
  border: 1px solid #082d60;
  cursor: pointer;
  font-family: 'Lato', serif;
  font-size: 16px;
  box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.09);
  transition: background-color 0.2s ease;
  width: 100%;
  &:hover {
    background: #1a3a72;
  }

  &:active {
    background: #0a2456;
    transform: translateY(1px);
  }

  @media ${minBp('tabletLarge')} {
    display: none;
  }
`;

export const ClearButton = styled.button`
  background: white;
  color: #082d60;
  padding: 14px 32px;
  border: 1px solid #082d60;
  cursor: pointer;
  font-family: 'Lato', serif;
  font-size: 16px;
  transition: background-color 0.2s ease;
  width: 100%;
  animation: fadeIn 0.3s ease-in;

  &:hover {
    background: #1a3a72;
    color: white;
  }

  &:active {
    background: #0a2456;
    transform: translateY(1px);
  }

  &:focus {
    outline: 2px solid #082d60;
    outline-offset: 2px;
  }

  @media ${minBp('tabletLarge')} {
    border: none;
    background: none;
    color: #0c2c64;
    font-family: 'Lato', serif;
    font-size: 14px;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
    transition: color 0.2s ease;

    &:hover,
    &:active {
      background: none;
      color: #204f90;
    }
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;

  @media ${minBp('tabletLarge')} {
    margin-top: 0;
  }
`;

export const MobileButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  background: #f2f2f2;
  padding: 10px 5px 10px 10px;

  @media ${minBp('tabletLarge')} {
    display: none;
  }
`;

export const DropdownsWrapper = styled.div`
  padding: 20px 0 16px 0;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media ${minBp('tabletLarge')} {
    display: contents;
  }

  .distance-dropdown {
    @media ${minBp('tabletLarge')} {
      display: none;
    }
  }
`;
