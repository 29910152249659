/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import LocationIcon from '../../assets/icons/Location.svg';
import BedIcon from '../../assets/icons/KingBed.svg';
import { minBp } from '../../lib/theme';
import { useShowHomes } from '../../context/ShowHomesContext';

const Card = styled.div`
  display: flex;
  background: #fff;
  width: 100%;
  flex-direction: column;

  @media ${minBp('tabletLarge')} {
    gap: 16px;
    height: 124px;
    flex-direction: row;
  }

  @media ${minBp('desktop')} {
    gap: 20px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 192px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media ${minBp('tablet')} {
    width: 100%;
    height: 220px;
  }

  @media ${minBp('tabletLarge')} {
    height: 100%;
    width: 147px;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 16px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f5f5;
  position: relative;
  gap: 8px;

  @media ${minBp('tabletLarge')} {
    padding: 24px 20px;
  }

  @media ${minBp('desktop')} {
    padding: 24px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const Title = styled.h3`
  font-family: 'Lato', serif;
  font-size: 16px;
  color: #082d60;
  font-weight: 400;
  margin: 0;
`;

const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 60%;

  @media ${minBp('tabletLarge')} {
    max-width: 80%;
  }
`;

const LocationIconSvg = styled.img`
  width: 16px;
  height: 16px;
`;

const Address = styled.p`
  font-family: 'Lato', serif;
  color: #082d60;
  margin: 0;
  font-size: 14px;
  font-weight: 300;
  text-wrap: balance;

  @media ${minBp('tabletLarge')} {
    font-size: 12px;
    font-weight: 400;
  }
`;

const Distance = styled.span`
  font-family: 'Lato', serif;
  color: #082d60;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Bedrooms = styled.div`
  font-family: 'Lato', serif;
  color: #082d60;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ViewButton = styled.button`
  border: none;
  position: absolute;
  bottom: 0;
  right: 0;
  background: #082d60;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out, transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: #0a3875;

    &:after {
      transform: rotate(45deg) translateX(1px);
    }
  }

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border-right: 2px solid white;
    border-top: 2px solid white;
    transform: rotate(45deg);
    margin-left: -4px;
  }
`;

const ContentLink = styled.a`
  text-decoration: none;
  display: contents;

  &:hover {
    ${ViewButton} {
      background: #0a3875;

      &:after {
        transform: rotate(45deg) translateX(1px);
      }
    }
  }
`;

const ShowHomeCard = ({ showHome, development, index }) => {
  const { state } = useShowHomes();
  const { labels } = state;

  return (
    <Card>
      <ImageContainer>
        <img src={showHome.ImageUrl} alt={showHome.PropertyType} />
      </ImageContainer>
      <ContentLink href={development.PageUrl}>
        <Content>
          <Header>
            <Distance>
              <LocationIconSvg src={LocationIcon} alt="location" />
              {development.DistanceInMiles
                ? `${Math.round(development.DistanceInMiles)} miles`
                : '0 miles'}
            </Distance>
            <Bedrooms>
              <img src={BedIcon} alt="bed" />
              {`${showHome.Bedrooms} ${
                showHome.Bedrooms == 1
                  ? labels?.bedroomsFilterUnset.slice(0, -1)
                  : labels?.bedroomsFilterUnset
              }`}
            </Bedrooms>
          </Header>
          <Title>
            {index + 1}. {showHome.PropertyType}
          </Title>
          <LocationWrapper>
            <Address>
              {development.Name}, {development.Town}, {development.PostCode}
            </Address>
          </LocationWrapper>
          <ViewButton></ViewButton>
        </Content>
      </ContentLink>
    </Card>
  );
};

export default ShowHomeCard;
