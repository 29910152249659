/* eslint-disable react/prop-types */
import React from 'react';
import {
  Card,
  ImageContainer,
  Image,
  Content,
  Title,
  Summary,
  Cta
} from './NewsCard.styles';

const NewsCard = ({ title, summary, image, pageUrl, index }) => {
  return (
    <Card index={index}>
      {image && (
        <ImageContainer>
          <Image src={image.Url} alt={image.Alt || title} loading="lazy" />
        </ImageContainer>
      )}
      <Content>
        <Title>{title}</Title>
        <Summary>{summary}</Summary>
        <Cta href={pageUrl}>Read more</Cta>
      </Content>
    </Card>
  );
};

export default NewsCard;
