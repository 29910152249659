import React, { useState, useEffect, useCallback } from 'react';
import {
  GoogleMap,
  Circle,
  Marker,
  MarkerClusterer
} from '@react-google-maps/api';
import { useShowHomes } from '../../context/ShowHomesContext';
import LocationIcon from '../../assets/icons/Location.svg';
import LocationWhiteIcon from '../../assets/icons/LocationWhite.svg';
import MapCircleMarker from '../../assets/icons/MapCircleMarker.svg';
import SideDrawer from './SideDrawer';

export const ShowHomesWrappedMap = () => {
  const { state, dispatch } = useShowHomes();
  const { Developments = [], Location = {} } = state.results ?? {};
  const { searchTerm } = state;

  const [center, setCenter] = useState({
    lat: 52.4862,
    lng: -1.8904
  });
  const [selectedDevelopment, setSelectedDevelopment] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [mapRef, setMapRef] = useState(null);

  const calculateZoom = radius => {
    const radiusInMiles = parseFloat(radius);
    if (radiusInMiles <= 20) return 10;
    if (radiusInMiles <= 40) return 9;
    if (radiusInMiles <= 60) return 8;
    if (radiusInMiles <= 80) return 7;
    return 6;
  };

  const onLoad = useCallback(map => {
    setMapRef(map);
  }, []);

  useEffect(() => {
    if (!mapRef) return;

    if (Location) {
      const newCenter = {
        lat: parseFloat(Location.Latitude),
        lng: parseFloat(Location.Longitude)
      };
      setCenter(newCenter);
      dispatch({ type: 'SET_CENTER', payload: newCenter });
    }

    // Default view for UK when no search has been performed
    if (!searchTerm) {
      mapRef.setZoom(8);
    }
  }, [mapRef, searchTerm, state.results, dispatch]);

  const handleMarkerClick = development => {
    setSelectedDevelopment(development);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedDevelopment(null);
  };

  const radiusOptions = {
    fillColor: '#082D60',
    fillOpacity: 0.15,
    strokeColor: '#082D60',
    strokeOpacity: 0,
    strokeWeight: 1,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1
  };

  const getMarkerIcon = development => {
    const isSelected =
      selectedDevelopment &&
      selectedDevelopment.Latitude === development.Latitude &&
      selectedDevelopment.Longitude === development.Longitude;

    return {
      url: isSelected ? LocationWhiteIcon : LocationIcon,
      scaledSize: new window.google.maps.Size(30, 40),
      anchor: new window.google.maps.Point(15, 40)
    };
  };

  const centerMarkerIcon = {
    url: MapCircleMarker,
    scaledSize: new window.google.maps.Size(20, 20),
    anchor: new window.google.maps.Point(10, 10)
  };

  // Grouping logic to aggregate markers with the same coordinates
  const groupDevelopmentsByLocation = developments => {
    const grouped = {};
    developments.forEach(dev => {
      const key = `${dev.Latitude},${dev.Longitude}`;
      if (!grouped[key]) {
        grouped[key] = { ...dev, ShowHomes: [] };
      }
      grouped[key].ShowHomes = grouped[key].ShowHomes.concat(dev.ShowHomes);
    });
    return Object.values(grouped);
  };

  const groupedDevelopments = Developments
    ? groupDevelopmentsByLocation(Developments)
    : [];

  const generateClusterSvg = count => {
    const svgContent = `
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40">
          <circle cx="20" cy="20" r="20" fill="white" />
          <text x="20" y="25" text-anchor="middle" font-size="12" fill="black" font-weight="400">${count}</text>
        </svg>
      `;
    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgContent)}`;
  };

  const clusterStyles = {
    calculator: markers => {
      return {
        text: markers.length.toString(),
        index: 1
      };
    },
    styles: [
      {
        url: generateClusterSvg(''),
        height: 24,
        width: 24,
        textColor: 'black',
        textSize: 12,
        zIndex: 1
      }
    ]
  };

  return (
    <GoogleMap
      onLoad={onLoad}
      zoom={calculateZoom(state.radius)}
      center={center}
      mapContainerStyle={{ width: '100%', height: '100%' }}
      options={{
        streetViewControl: false,
        mapTypeControl: true,
        fullscreenControl: false
      }}
    >
      {searchTerm && center && (
        <>
          <Circle
            center={center}
            radius={state.radius * 1609.34}
            options={radiusOptions}
          />
          <Marker
            position={center}
            icon={centerMarkerIcon}
            zIndex={2}
            pointerEvents="none"
          />
        </>
      )}

      <MarkerClusterer
        options={clusterStyles}
        onClusteringBegin={clusterer => {
          // Update cluster icons with actual counts
          const clusters = clusterer.getClusters();
          clusters.forEach(cluster => {
            const count = cluster.getSize();
            if (cluster.clusterIcon_) {
              cluster.clusterIcon_.url_ = generateClusterSvg(count);
              cluster.clusterIcon_.setupStyles();
            }
          });
        }}
      >
        {clusterer =>
          groupedDevelopments.map((development, idx) => (
            <Marker
              key={idx}
              position={{
                lat: parseFloat(development.Latitude),
                lng: parseFloat(development.Longitude)
              }}
              onClick={() => handleMarkerClick(development)}
              clusterer={clusterer}
              icon={getMarkerIcon(development)}
            />
          ))
        }
      </MarkerClusterer>

      <SideDrawer
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose}
        development={selectedDevelopment}
        searchTerm={searchTerm}
      />
    </GoogleMap>
  );
};
