/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import SearchIcon from '../../assets/icons/Target.svg';
import MagnifierIcon from '../../assets/icons/Magnifier.svg';
import TargetIcon from '../../assets/icons/Target2.svg';
import styled from 'styled-components';
import { maxBp, minBp } from '../../lib/theme';
import { useShowHomes } from '../../context/ShowHomesContext';
import { getHostname } from '../../utility/helpers';
import { useDebounce } from '../../utility/helpers';

const SearchInputComponent = styled.div`
  position: relative;
  background-color: #fff;
  width: 100%;
  padding: 16px 16px 0;
  animation: fadeIn 0.3s ease-in;

  @media ${minBp('tabletLarge')} {
    padding: 0;
  }

  input {
    filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.25));
    padding: 14px 50px 14px 40px;
    border: none;
    color: #082d60;
    font-family: 'Lato', serif;
    font-size: 16px;
    width: 100%;

    &::placeholder {
      @media (max-width: 1023px) {
        content: attr(data-mobile-placeholder);
      }
    }

    @media ${minBp('tabletLarge')} {
      min-width: 300px;
      padding: 14px 48px 14px 16px;
    }

    &:focus {
      outline: 2px solid #082d60;
      outline-offset: 2px;
    }
  }

  img {
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    margin: 14px;

    @media ${minBp('tabletLarge')} {
      top: 0;
      right: 0;
    }

    &.target-icon-mob {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 18px;
      left: 15px;
      z-index: 1;
      cursor: pointer;
    }

    &.search-icon,
    &.search-icon-mob {
      position: absolute;
      cursor: pointer;
    }

    &.search-icon-mob {
      width: 24px;
      height: 24px;
      top: 15px;
      right: 15px;
    }

    &.hide-on-desktop {
      @media ${minBp('tabletLarge')} {
        display: none;
      }
    }

    &.hide-on-mobile {
      @media ${maxBp('tabletLarge')} {
        display: none;
      }
    }
  }
`;

const SuggestionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 16px;
  right: 16px;
  background: white;
  z-index: ${props => (props.isOpen ? 2 : 0)};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  border-radius: 0 0 15px 15px;
  padding: 15px 0 5px 0;
  animation: fadeIn 0.3s ease-in;
  font-family: 'Lato', serif;
  font-size: 16px;
  font-weight: 300;
  color: #082d60;

  @media ${minBp('tabletLarge')} {
    left: 0;
    right: 0;
  }

  .suggestions-inner {
    max-height: 175px;
    overflow-y: auto;
    width: 99%;

    @media ${minBp('tabletLarge')} {
      max-height: 200px;
    }

    /* Custom scrollbar styling */
    &::-webkit-scrollbar {
      width: 5px;
      background: white;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
      background: white;
      border-radius: 20px;
      margin-block: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      background: #082d60;
      border-radius: 20px;
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: #082d60 #fff;
  }
`;

const SuggestionItem = styled.div`
  padding: 10px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    font-weight: 400;
  }
`;

const SearchInput = ({ value, onChange, onSearch }) => {
  const { state } = useShowHomes();
  const { labels } = state;
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (debouncedSearchTerm.length < 2) {
        setSuggestions([]);
        return;
      }

      try {
        const response = await fetch(
          `${getHostname()}/bloorapi/autocomplete?query=${encodeURIComponent(
            debouncedSearchTerm
          )}`
        );
        if (!response.ok) throw new Error('Failed to fetch suggestions');
        const data = await response.json();
        setSuggestions(data);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [debouncedSearchTerm]);

  const handleInputChange = e => {
    setSearchTerm(e.target.value);
    onChange(e);
  };

  const handleSuggestionClick = suggestion => {
    const formatLocation = suggestion => {
      const name = suggestion.Name || '';
      const county = suggestion.County
        ? suggestion.County.replace(/"/g, '')
        : '';

      if (!name && !county) return '';
      if (!county) return name;
      if (!name) return county;

      return `${name}, ${county}`;
    };

    const location = formatLocation(suggestion);
    const filters = state.filters || {};

    onChange({ target: { value: location } });
    setShowSuggestions(false);
    onSearch({
      term: location,
      bedrooms: filters.bedrooms || [],
      radius: filters.radius || '40',
      propertytypes: filters.propertytypes || []
    });
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      const filters = state.filters || {};
      onSearch({
        term: value,
        bedrooms: filters.bedrooms || [],
        radius: filters.radius || '40',
        propertytypes: filters.propertytypes || []
      });
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLocationClick = async () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by your browser');
      return;
    }

    setIsLoading(true);
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;
      const response = await fetch(
        `${getHostname()}/bloorapi/locationaddress?latitude=${latitude}&longitude=${longitude}`
      );

      if (!response.ok) throw new Error('Failed to get address');

      const data = await response.json();
      if (data.Success && data.Address) {
        const filters = state.filters || {};

        onChange({ target: { value: data.Address } });
        onSearch({
          term: data.Address,
          bedrooms: filters.bedrooms || [],
          radius: filters.radius || '40',
          propertytypes: filters.propertytypes || []
        });
      }
    } catch (error) {
      console.error('Error in handleLocationClick:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SearchInputComponent>
      <div className="search-input-container">
        <img
          src={TargetIcon}
          alt="Target Icon"
          className="hide-on-desktop target-icon-mob"
          onClick={handleLocationClick}
        />
        <input
          type="text"
          placeholder={
            isMobile
              ? labels?.searchPromptMobile || 'Location or postcode'
              : labels?.searchPrompt || 'Enter your postcode or town'
          }
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onFocus={() => setShowSuggestions(true)}
          onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
        />
        {showSuggestions && suggestions.length > 0 && (
          <SuggestionsContainer isOpen={showSuggestions}>
            <div className="suggestions-inner">
              {suggestions.map((suggestion, index) => {
                const name = suggestion.Name || '';
                const county = suggestion.County
                  ? suggestion.County.replace(/"/g, '')
                  : '';

                return (
                  <SuggestionItem
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {name && county ? `${name}, ${county}` : name || county}
                  </SuggestionItem>
                );
              })}
            </div>
          </SuggestionsContainer>
        )}
      </div>
      <img
        src={SearchIcon}
        alt="Search Icon"
        onClick={isLoading ? undefined : handleLocationClick}
        className="search-icon hide-on-mobile"
      />
      <img
        src={MagnifierIcon}
        alt="Magnifier Icon"
        onClick={onSearch}
        className="search-icon-mob hide-on-desktop"
      />
    </SearchInputComponent>
  );
};

export default SearchInput;
